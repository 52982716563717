<template>
  <div class="header_box">
    <img src="../assets/index/headerLogo.png" alt="" style="cursor: pointer" @click="gotoHome()">
    <auto-search ref="autoSearch" v-if="searchType" :keywords="headerQo.keyword" class="headSearch"
                 @changeKeyword="changeKeyword" @searchEvent="searchEvent"></auto-search>
    <div class="btn">
      <div round class="language">
        <img src="../assets/index/info.png" alt="">
        <el-select v-model="headerLanguage" placeholder="请选择" class="language-select">
          <el-option v-for="item in options" :label="$t(item.label)" :value="item.value"></el-option>
        </el-select>
      </div>

      <div class="login" @click="loginPage" v-if="!userInfo">
        <img src="../assets/index/user.png" alt="">{{ $t("lan_287") }}
      </div>
      <div class="login" @click="registerPage" v-if="!userInfo">
        <img src="../assets/index/register-icon.png" style="width: 23px;height: 24px" alt="">{{ $t("lan_291") }}
      </div>
      <el-popover
          v-if="userInfo"
          placement="bottom"
          width="100"
          trigger="click">
        <div class="login-menu-box">
          <div @click="personCenter">{{ $t("lan_5") }}</div>
          <div @click="logOut">{{$t("lan_288")}}</div>
        </div>
        <div slot="reference" class="login">
          <img src="../assets/index/user.png" alt="">{{ userInfo.name }}
        </div>
      </el-popover>
    </div>
  </div>
</template>

<script>
import AutoSearch from '@/components/AutoSearch';
import {getToken, setToken} from "@/utils/auth";
import {getInfo} from "@/api/user";

var _this;
export default {
  name: 'MeteorologicalHeader',
  components: {
    AutoSearch
  },
  props: ['qo', "searchType", "searchFlag"],
  data() {
    return {
      headerQo: this.qo,
      headerLanguage: this.language,
      options: [
        {label: "lan_286", value: "en"},
        {label: "lan_285", value: "zh"}
      ],
      userInfo: null
    };
  },
  watch: {
    "headerQo.keyword": {
      handler(val, oldVal) {
        if (this.searchType && this.$refs && this.$refs.autoSearch) {
            this.$refs.autoSearch.changeValue(val)
        }
      },
      immediate: true
    },
      headerLanguage(newValue, oldValue) {
          if (newValue == oldValue || oldValue == undefined) {
              return;
          }
          localStorage.setItem("language", newValue);
          this.$nextTick(()=>{
              this.$i18n.locale = newValue;
              window.location.reload();
          })

      },
  },
   created(){
       _this = this;
       if (localStorage.getItem("language")) {
           this.headerLanguage = localStorage.getItem("language")
       }else{
           this.headerLanguage = "en";
       }

       if (getToken()) {
           getInfo().then(res => {
               _this.userInfo = res.data;
           });
       }
   },
  mounted() {

  },
  methods: {
    loginPage() {
      _this.$router.push("/login");
    },
    registerPage(){
        _this.$router.push("/register");
    },
    searchEvent(value) {
      this.headerQo.keyword = value;
      this.$refs.autoSearch.changeValue(value)
      localStorage.removeItem("qo")
      if (this.searchFlag) {
        this.$router.push({path: '/meteorologicalSearch', query: {keyword: value}});
      } else {
        this.$emit("searchList", this.headerQo)
      }

    },
    changeKeyword(keywords) {
      this.$set(this.headerQo, 'keywords', keywords);
      this.$emit("changeKeyword", keywords)
    },
    fuKeyword(keywords){
        this.$set(this.headerQo, 'keywords', keywords);
        console.log(this.headerQo.keywords,"header1")
        _this.$refs.autoSearch.changeValue(keywords)
        console.log(keywords,"header2")
    },
    logOut() {
      setToken("");
      _this.$router.push("/login");
    },
    personCenter() {
      _this.$router.push({path: "/manage/baseInfo", query: {t: new Date().getTime().toString()}})
    },
    gotoHome(){
        _this.$router.push("/");
    }
  },
};
</script>

<style lang="scss" scoped rel="stylesheet/scss">

.login-menu-box {
   div {
     width: 100%;
     text-align: center;
     height: 40px;
     line-height: 40px;
     cursor: pointer;
     white-space: nowrap;
   }

  div:hover {
    background-color: #e3e3e3;
  }
}
.header_box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 146px 0 146px;
  width: 100%;
  height: 96px;
  line-height: 96px;
  background-color: #14195a;
  //position: fixed;
  //top: 0;

  img {
    /*margin-top: 17px;*/
    /*width: 253px;*/
    height: 50px;
  }

  .btn {
    display: flex;
    font-size: 23px;

    font-weight: 400;
    color: #133DA9;
    text-align: center;
  }

  .language {
    width: 125px;
    height: 40px;
    color: #133DA9;
    background: #FFFFFF;
    border-radius: 22px;
    line-height: 40px;
    cursor: pointer;
    font-size: 16px;

    img {
      vertical-align: middle;
      width: 18px;
      height: 18px;
      margin-left: 15px;
    }

    .language-select {
      display: inline-block;
      width: 88px;
    }
  }

  .login {
    margin-left: 22px;
    width: 120px;
    height: 40px;
    background: #133DA9;
    border-radius: 22px;
    color: #FFFFFF;
    line-height: 40px;
    cursor: pointer;
    font-size: 16px;

    img {
      vertical-align: middle;
      width: 18px;
      height: 18px;
      margin-right: 11px;
    }
  }
}

.headSearch {
  margin-left: 200px;
  flex: 1;
}
</style>
<style lang="scss">
.header_box .el-input-group {
  width: 600px !important;
  height: 40px;
}

.headSearch .el-input__inner {
  padding-left: 39px;
  width: 600px;
  height: 40px;
  background: #FFFFFF;
  padding-right: 120px !important;
  border-radius: 25px !important;
}

.headSearch .el-input__inner::placeholder {
  font-size: 17px;

  font-weight: 400;
  color: #393939;
}

.headSearch .el-input-group__append .el-button {
  width: 87px;
  height: 40px;
  background: #133DA9;
  color: #FFFFFF;
  font-size: 25px;
  border-radius: 0px 25px 25px 0;
}

.headSearch .el-input-group__append {
  background: transparent !important;
  border: none !important;
}


.headSearch .el-input__suffix{
  width: 87px;
  height: 40px;
  background: #133DA9;
  color: #FFFFFF;
  font-size: 25px;
  border-radius: 0px 25px 25px 0;
  position: absolute;
  top: 28px;
  left: 515px;
  cursor: pointer;

  .suffix-box{
    width: 87px;
    height: 40px;
  }

  i {
    color: #ffffff;
    font-size: 25px;
    line-height: 40px;
    display: block;
  }
}

.headSearch .el-input__suffix-inner {
  width: 100%;
  height: 100%;
  display: block;
}

.language-select .el-input__inner {
  background: transparent !important;
  border: unset !important;
  padding-left: 11px !important;
  padding-right: 20px !important;
}

.language-select .el-select__caret {
  width: 20px;
}
</style>
