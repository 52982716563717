import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import {getToken, setToken, removeToken} from '@/utils/auth'

import '@/permission'
import axios from 'axios'
import VueI18n from 'vue-i18n'

import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import elementEnLocale from 'element-ui/lib/locale/lang/en' // element-ui英文包
import elementZhLocale from 'element-ui/lib/locale/lang/zh-CN'// element-ui中文包
import enLocale from '@/assets/language/en' // element-ui英文包
import zhLocale from '@/assets/language/zh'// element-ui中文包
import locale from 'element-ui/lib/locale/'

// 文字高亮
import TextHighlight from "vue-text-highlight";
Vue.component("text-highlight", TextHighlight);

import * as echarts from 'echarts';
Vue.prototype.$echarts = echarts;

Vue.config.productionTip = false

Vue.use(VueI18n);
Vue.use(ElementUI);

Vue.filter('num', (val) => {
  val = '' + val // 转换成字符串
  let int = val
  int = int.split('').reverse().join('') // 翻转整数
  let temp = '' // 临时变量
  for (let i = 0; i < int.length; i++) {
    temp += int[i]
    if ((i + 1) % 3 === 0 && i !== int.length - 1) {
      temp += ',' // 每隔三个数字拼接一个逗号
    }
  }
  temp = temp.split('').reverse().join('') // 加完逗号之后翻转
  return temp // 返回
})

const i18n = new VueI18n({
    locale: localStorage.getItem('language') || "en",
    messages: {
        'en': {
            ...enLocale,
            ...elementEnLocale
        },
        'zh': {
            ...zhLocale,
            ...elementZhLocale
        }
    }
})

locale.i18n((key, value) => i18n.t(key, value))

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')

export default i18n