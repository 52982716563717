<template>
  <div class="index_box">
    <header-com ref="header" :searchType="false"></header-com>
    <div class="index_main">
      <img src="../assets/index/logo.png" alt="" class="index_logo">
      <div class="index_search">
        <auto-search ref="autoSearch" :keywords="qo.keyword" class="index_input"
                     @searchEvent="searchEvent"></auto-search>
      </div>
      <div class="index_list">
        <div v-for="(item,index) in list" :key="index" class="list" @click="listEvent(item)">
          <img :src="imgList[index]" alt="">
          <div class="index_Data">
            <p>{{ $t(title[index])}}</p>
            <span>{{ formatData(item) | num }}</span>
          </div>
        </div>
      </div>
    </div>
    <footer-com style="position: fixed;bottom: 0"></footer-com>
  </div>
</template>

<script>
import {get_statistical} from "@/api/index";
import HeaderCom from '@/components/Header';
import AutoSearch from '@/components/AutoSearch';
import footerCom from '@/components/footer';

var _this;
export default {
  name: 'IndexView',
  components: {
    HeaderCom,
    AutoSearch,
    footerCom,
  },
  data() {
    return {
      search: "",
      list: ["Papers", 'DataSet', 'Satellite', 'Instruments', 'Reports'],
      title: ["lan_211","lan_212","lan_213","lan_214","lan_215"],
      imgList: [require("../assets/index/article.png"),
        require("../assets/index/dataSet.png"),
        require("../assets/index/Satellite.png"),
        require("../assets/index/Instruments.png"),
        require("../assets/index/Reports.png")],
      statisticalData: {
        paperCount: 0,
        dpCount: 0,
        satelliteCount: 0,
        dlCount: 0,
        ipccCount: 0
      },
      qo: {
        keyword: '',
        pageNo: 1,
        pageSize: 10,
      },
      listType: "",
      language: "en"
    };
  },
  created() {
    _this = this;
      if (localStorage.getItem("language")) {
          this.language = localStorage.getItem("language")
      }
  },
  mounted() {
    this.getStatistical()
  },

  methods: {
    //获取列表数量
    getStatistical() {
      get_statistical().then(res => {
        _this.statisticalData = res.data;
      })
    },
    formatData(key) {
      switch (key) {
        case "Papers":
          return _this.statisticalData.paperCount
        case "DataSet":
          return _this.statisticalData.dpCount
        case "Satellite":
          return _this.statisticalData.satelliteCount
        case "Instruments":
          return _this.statisticalData.dlCount
        case "Reports":
          return _this.statisticalData.ipccCount
        default:
          break;
      }
    },
    listEvent(item) {
      this.listType = item;
      if(item == 'DataSet'){
          localStorage.setItem("tabTitle", "DataSetMy");
      }else{
          localStorage.setItem("tabTitle", item);
      }
      this.$router.push({path: '/meteorologicalSearch', query: {keyword: ""}});
    },
    searchEvent(keyword) {
      localStorage.removeItem("qo")
      this.qo.keyword = keyword
      this.$router.push({path: '/meteorologicalSearch', query: {keyword: this.qo.keyword}});
    }
  },
};
</script>

<style lang="scss" scoped rel="stylesheet/scss" type="text/scss">
.index_box {
  width: 100%;
  height: 100%;
  /*background: url("../assets/index/background.png") no-repeat center/contain;*/
  background-image: url("../assets/index/background.png");
  background-size: 609px 780px;
  background-repeat: no-repeat;
  background-position: left top;

  .index_main {
    text-align: center;
    height: calc(100% - 255px);

    .index_logo {
      margin-top: 120px;
      height: 80px;
    }

    .index_search {
      margin: 51px 417px 121px;
      /*width: 1087px;*/

      .index_input {
        border-radius: 35px 35px 0 0;
      }
    }

    .index_list {
      //display: flex;
      width: 1680px;
      margin: 0 auto;
      padding-left: 60px;

      /*justify-content: space-evenly;*/
      .list {
        position: relative;
        display: inline-block;
        margin-right: 50px;
        z-index: 1111;
        cursor: pointer;
      }

      .list:last-child {
        margin-left: 0;
      }

      img {
        /*width: 277px;*/
        height: 200px;
      }

      .index_Data {
        position: absolute;
        left: 50%;
        bottom: 19px;
        transform: translate(-50%, 0%);
        cursor: pointer;

        p {
          font-size: 22px;

          font-weight: 400;
          margin-bottom: 18px;
          color: #FFFFFF;
        }

        span {
          font-size: 18px;

          font-weight: 400;
          color: #FFFFFF;
          color: rgba(255, 255, 255, 0.37);
        }
      }
    }
  }
}
</style>

<style lang="scss">
.index_search .index_input .el-input__inner {
  padding-left: 58px;
  height: 60px;
  font-size: 25px;
  color: #393939;
  border-radius: 35px;
  padding-right: 150px;
  border-right: unset !important;
}

.index_search .index_input .el-input__inner::placeholder {
  color: #393939;
}

.index_search .index_input .el-input-group__append {
  width: 120px;
  height: 60px;
  background: #133DA9;
  border-radius: 0px 35px 35px 0px;

  .el-button {
    color: #fff;
    font-size: 34px;
  }

}

.index_search .el-input__suffix{
  width: 120px;
  height: 60px;
  background: #133DA9;
  border-radius: 0px 35px 35px 0px;
  cursor: pointer;
  position: absolute;
  right: 0;

  .suffix-box{
    width: 120px;
    height: 60px;
    position: absolute;
    right: 0;
  }

  i {
    color: #ffffff;
    font-size: 34px;
    line-height: 60px;
  }
}

.index_search .el-input__suffix-inner {
  width: 100%;
  height: 100%;
  display: block;
}
</style>