import axios from 'axios';
import {Message} from 'element-ui';
import store from '@/store';
import {getToken} from "@/utils/auth";

var fileURL = "https://globalchange.oss-cn-beijing.aliyuncs.com/";
var localFileURL = "https://www.eoknowledgehub.cn/datasetfile/";
// var _baseURL = "http://localhost:4026";
var _baseURL = "https://www.eoknowledgehub.cn/api"
// create an axios instance
const service = axios.create({
    baseURL: _baseURL, // url = base url + request url
    // withCredentials: true, // send cookies when cross-domain requests
    timeout: 50000 // request timeout
});

// request interceptor
service.interceptors.request.use(
    config => {
        if (store.getters.token) {
            // let each request carry token
            // ['X-Token'] is a custom headers key
            // please modify it according to the actual situation
            config.headers['Authorization'] = getToken()
        }
        return config
    },
    error => {
        // do something with request error
        return Promise.reject(error)
    }
)

// response interceptor
service.interceptors.response.use(
    response => {
        const res = response.data
        // if the custom code is not 20000, it is judged as an error.
        if (res.code && res.code !== "SUCCESS") {
            Message({
                message: res.msg || 'Error',
                type: 'error',
                duration: 5 * 1000
            })

            // if (res.code == "UNAUTHORIZED") {
            //     store.dispatch('user/resetToken').then(() => {
            //         location.reload()
            //     })
            // }

            return Promise.reject(new Error(res.msg || 'Error'))
        } else {
            return res;
        }
    }, error => {
        Message({
            message: error.msg || "Error",
            type: 'error',
            duration: 5 * 1000
        })
        return Promise.reject(error)
    }
)

export default service;

export function getFileUrl() {
    return fileURL;
}

export function getLocalFileUrl() {
    return localFileURL;
}

Date.prototype.format = function (fmt) {
    var o = {
        "M+": this.getMonth() + 1, //月份
        "d+": this.getDate(), //日
        "h+": this.getHours(), //小时
        "m+": this.getMinutes(), //分
        "s+": this.getSeconds(), //秒
        "q+": Math.floor((this.getMonth() + 3) / 3), //季度
        "S": this.getMilliseconds() //毫秒
    };
    if (/(y+)/.test(fmt)) fmt = fmt.replace(RegExp.$1, (this.getFullYear() + "").substr(4 - RegExp.$1.length));
    for (var k in o)
        if (new RegExp("(" + k + ")").test(fmt)) fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
    return fmt;
}
