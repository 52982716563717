import router from './router'
import { getToken } from '@/utils/auth' // get token from cookie
import getPageTitle from '@/utils/get-page-title'

const whiteList = ['/login', '/register', '/forget', '/',"/meteorologicalSearch","/reportDetails",'/papersDetails','/dataSetDetails', '/dataSetMyDetails'] // no redirect whitelist

router.beforeEach(async(to, from, next) => {
  // set page title
  document.title = getPageTitle(to.meta.title)

  // determine whether the user has logged in
  const hasToken = getToken()
  // next()

  if (hasToken) {
      next()
  } else {
    if (whiteList.indexOf(to.path) !== -1 || to.path.indexOf("/eo/resource/instrument") !== -1 || to.path.indexOf("/eo/resource/satellite") !== -1) {
      next()
    } else {
      next(`/login`)
        return
    }
  }
})

// router.afterEach(() => {
//   // finish progress bar
// })
