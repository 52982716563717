import Vue from 'vue'
import VueRouter from 'vue-router'
import indexRouter from '../views/index.vue'


const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        component: indexRouter
    },
    {
        path: '/meteorologicalSearch',
        name: 'MeteorologicalSearch',
        component: () => import('../views/search.vue')
    },
    {
        path: '/reportDetails',
        name: 'ReportDetails',
        component: () => import('../views/reportDetails.vue')
    },
    {
        path: '/papersDetails',
        name: 'PapersDetails',
        component: () => import('../views/papersDetails.vue')
    },
    {
        path: '/dataSetDetails',
        name: 'DataSetDetails',
        component: () => import('../views/dataSetDetails.vue')
    },
    {
        path: '/dataSetMyDetails',
        name: 'DataSetMyDetails',
        component: () => import('../views/dataSetMyDetails.vue')
    },
    {
        path: '/eo/resource/satellite.:id',
        name: 'SatelliteDetails',
        component: () => import('../views/satelliteDetails.vue')
    },
    {
        path: '/eo/resource/instrument.:id',
        name: 'InstrumentsDetails',
        component: () => import('../views/instrumentsDetails.vue')
    },
    {
        path: '/login',
        name: 'Login',
        component: () => import('../views/login.vue')
    },
    {
        path: '/forget',
        name: 'Forget',
        component: () => import('../views/forget.vue')
    },
    {
        path: '/register',
        name: 'Register',
        component: () => import('../views/register.vue')
    },
    {
        path: '/demo',
        name: 'Demo',
        component: () => import('../views/demo.vue')
    },
    {
        path: '/homePage',
        component: () => import('../views/HomePage.vue')
    },
    {
        path: '/manage',
        component: () => import('@/views/ManageIndex'),
        children: [
            {
                path: 'baseInfo',
                component: () => import('@/components/manage/BaseInfo')
            },
            {
                path: 'personalInfoManage',
                component: () => import('@/components/manage/PersonalInfoManage')
            },
            {
                path: 'createData',
                component: () => import('@/components/manage/CreateData')
            },
            {
                path: 'supportedManage',
                component: () => import('@/components/manage/SupportedManage')
            },
            {
                path: 'deptManage',
                component: () => import('@/components/manage/DeptManage')
            },
            {
                path: 'userManage',
                component: () => import('@/components/manage/UserManage')
            },
            {
                path: 'roleManage',
                component: () => import('@/components/manage/RoleManage')
            },
            {
                path: 'menuManage',
                component: () => import('@/components/manage/MenuManage')
            },
            {
                path: 'draftData',
                component: () => import('@/components/manage/DraftData')
            },
            {
                path: 'reviewData',
                component: () => import('@/components/manage/ReviewData')
            },
            {
                path: 'publishData',
                component: () => import('@/components/manage/PublishedData')
            },
            {
                path: 'previewPage',
                component: () => import('@/components/manage/PreviewPage')
            },
            {
                path: 'applyAnnexManage',
                component: () => import('@/components/manage/ApplyAnnexManage')
            },
            {
                path: 'publishedManage',
                component: () => import('@/components/manage/PublishedDataManage')
            },
            {
                path: 'repliedManage',
                component: () => import('@/components/manage/RepliedDataManage')
            },
            {
                path: 'reviewManage',
                component: () => import('@/components/manage/ReviewDataManage')
            },
            {
                path: 'homePage',
                component: () => import('@/components/manage/HomePage')
            },
            {
                path: 'underReviewDataManage',
                component: () => import('@/components/manage/UnderReviewDataManage')
            },
            {
                path: 'underReviewData',
                component: () => import('@/components/manage/UnderReviewData')
            },
            {
                path: 'logManage',
                component: () => import('@/components/manage/LogManage')
            },
			{
                path: 'statistics',
                component: () => import('@/components/manage/Statistics')
            },
            {
                path: 'trafficStatistics',
                component: () => import('@/components/manage/TrafficStatistics')
            },
            {
                path: '/',
                redirect: "baseInfo"
            },
        ]
    },
]

const router = new VueRouter({
    mode: 'history',
    base: '/',
    routes: routes
})

export default router
