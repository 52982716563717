<template>
  <div>
    <el-autocomplete popper-class="my-autocomplete" v-model="value" style="width: 100%" @input="inputValue"
                     :fetch-suggestions="querySearch" placeholder="" @select="handleSelect"
                     @keyup.enter.native="searchButton">
      <span slot="suffix" class="suffix-box"  @click="searchButton">
         <i  class="el-icon-search"></i>
      </span>
      <template slot-scope="{ item }">
        <div style="display: flex" :title="formatName(item.name)+'('+formatNameCn(item)+')'">
          <span class="select-down-box" v-if="item.name">{{ formatName(item.name) }}</span>
          <span class="select-down-box" style="text-align: right;margin-left: 20px">{{ formatNameCn(item) }}</span>
        </div>
      </template>
    </el-autocomplete>
  </div>
</template>

<script>
import {search_field_and_author_page} from "@/api/search";

export default {
  name: 'AutoSearch',
  props: ["keywords"],
  data() {
    return {
      value: this.keywords
    };
  },
  mounted() {

  },

  methods: {
    formatName(value) {
      if (value != null && value) {
        return value.replace(/<[^<>]*>/g, '')
      }
      return value
    },
    formatNameCn(item) {
      if (item._class == 'EsAuthor') {
        return "Author"
      } else {
        if (item.nameCn) {
          return item.nameCn.replace(/<[^<>]*>/g, '')
        }
        return ""
      }
    },
    querySearch(queryString, cb) {
      if (!queryString) {
        cb([]);
        return false;
      }
      search_field_and_author_page({keyword: queryString, pageSize: 20, pageNo: 1}).then(res => {
        cb(res.data.content);
      })
    },
    changeValue(value) {
        console.log(value,"value")
      this.value = value;
    },
    inputValue() {
      this.$emit("changeKeyword", this.value)
    },
    handleSelect(item) {
      this.value = item.name.replace(/<[^<>]*>/g, '')
      this.$emit("changeKeyword", this.value)
      this.$emit("searchEvent", this.value)
    },
    searchButton() {
      this.$emit("searchEvent", this.value)
    }
  },
};
</script>

<style lang="scss" scoped rel="stylesheet/scss" type="text/scss">
.select-down-box {
  overflow: hidden; //超出的文本隐藏
  text-overflow: ellipsis; //溢出用省略号显示
  white-space: nowrap;
  flex: 1
}
</style>