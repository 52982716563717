<template>
    <div class="footer_box">
         <!--<img src="../assets/index/logo.png" alt="">-->
        <p >Powered by Earth Information Science Lab, Department of Earth System Science, Tsinghua University</p>
        <span>巴比特 增值电信业务经营许可证：合字B2-20210152   浙ICP备14013035号-22</span>
    </div>
</template>

<script>
export default {
    name: 'MeteorologicalFooter',

    data() {
        return {
            
        };
    },

    mounted() {
        
    },

    methods: {
        
    },
};
</script>

<style lang="scss" scoped>
.footer_box{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding:0 280px 0 213px ;
    width: 100%;
    height: 50px;
    background: #F8F8F8;
    line-height: 50px;
    /*img{*/
        /*width: 319px;*/
        /*height: 78px;*/
    /*}*/
    
    p{
        padding: 0;
        margin: 0;
        font-size: 16px;

        font-weight: 400;
        color: #28231E;

    }
    span{
        font-size: 13px;

        font-weight: 400;
        color: #7F7F7F;
    }
   
}
</style>