<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>
<script>
import request from "@/utils/request";

export default {
  data() {
    return {
    };
  },
  mounted() {
    this.browsingOnce();
  },
  methods: {
    browsingOnce() {
      request({
        url: "/gl-insite-brow/create",
        method: "post",
        data: {}
      }).then(() => {
      });
    }
  }
}
</script>
<style lang="scss">

/*去除ie edge的密码框默认出现的小眼睛
		给人添乱这一块，巨硬从来都没让我失望过*/
input[type="password"]::-ms-reveal{
  display: none;
}
input[type="password"]::-ms-clear{
  display: none;
}
input[type="password"]::-o-clear {
  display: none;
}


:root{
  --fontSize: 15px;
}


* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family:Cambria,SimHei;
}

html,
body,
#app {
  width: 100%;
  height: 100%;

}
ul,
li {
  list-style: none;
}
a {
  text-decoration: none;
}


.text__highlight{
  background: transparent!important;
  color: red;
}

.el-table {
  .el-table__body {
    .cell {
      font-size: 15px;
    }
  }
  .has-gutter {
    th {
      background: #eff1f6!important;
      /*font-weight: 500;*/
      font-size: 15px;
    }
  }

  .el-table__cell.is-leaf{
    background: #eff1f6;
    /*font-weight: 500;*/
    font-size: 15px;
  }
}

.content-box .el-button--primary{
  background-color: #648CF0!important;
  border-color: #648CF0!important;
  border-radius: 4px!important;
}

.el-button--primary.is-disabled{
  background: #F5F5F5!important;
  border: 1px solid #CCCCCC!important;
  color: #999999!important;
}

.content-box  .el-button--text{
  color: #648CF0!important;
}

.el-drawer__header{
  color: #000000!important;
  border-bottom: 1px solid #e8e8e8;
  padding-bottom: 20px!important;
}
.anchorBL img{
  display: none;
}

.BMap_cpyCtrl{
  display: none;
}

.table td:nth-child(odd){
  background-color: #98C2F4;
  padding: 15px 20px;
  width: 270px;
  font-weight: bold;
}

.table td:nth-child(even){
  background-color: #e5ecf9;
  padding: 15px 20px;
}

.table-wavelength-info td:nth-child(odd){
  background-color: #98C2F4;
  padding: 15px 20px;
  width: 200px;
  font-weight: bold;
}

.table-wavelength-info td:nth-child(even){
  background-color: #e5ecf9;
  padding: 15px 20px;
}

.table-wavelength tr:first-child td{
  background-color: #98C2F4;
  padding: 15px 20px;
  font-weight: bold;
}

.table-wavelength tr:not(:first-child) td{
  background-color: #e5ecf9;
  padding: 15px 20px;
}
</style>
