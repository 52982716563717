import request from '@/utils/request'


//树形图数据
export function search_list_fields() {
    return request({
        url: '/font-search/search_list_fields',
        method: 'post',
    })
}

//报告查询
export function search_ipcc_page(data) {
    return request({
        url: '/font-search/search_ipcc_page',
        method: 'post',
        data
    })
}

//卫星分页查询
export function search_satellite_page(data) {
    return request({
        url: '/font-search/search_satellite_page',
        method: 'post',
        data
    })
}

//数据集分页查询
export function search_datapacket_page(data) {
    return request({
        url: '/font-search/search_datapacket_page',
        method: 'post',
        data
    })
}

//本中心数据集分页查询
export function search_dataset_page(data) {
    return request({
        url: '/font-search/search_dataset_page',
        method: 'post',
        data
    })
}

//论文分页查询
export function search_paper_page(data) {
    return request({
        url: '/font-search/search_paper_page',
        method: 'post',
        data
    })
}

//载荷分页查询
export function search_dataload_page(data) {
    return request({
        url: '/font-search/search_dataload_page',
        method: 'post',
        data
    })
}

//ipcc报告页码查询
export function source_pageindex(source, pageindex) {
    return request({
        url: `/font-search/search_ipccleaf_by_source_pageindex/${source}/${pageindex}`,
        method: 'GET',
    })
}

//ipcc报告详情
export function search_ipccleaf(source) {
    return request({
        url: `/font-search/search_ipccleafpageindex_by_source/${source}`,
        method: 'GET',
    })
}

//论文详情查询
export function get_one_paper(id) {
    return request({
        url: `/font-search/get_one_paper/${id}`,
        method: 'GET',
    })
}

//satellite卫星详情查询
export function get_one_satellite(id) {
    return request({
        url: `/font-search/get_one_satellite/${id}`,
        method: 'GET',
    })
}

//数据集详情查询
export function get_one_datapacket(id) {
    return request({
        url: `/font-search/get_one_datapacket/${id}`,
        method: 'GET',
    })
}

//载荷详情查询
export function get_one_dataload(id) {
    return request({
        url: `/font-search/get_one_dataload/${id}`,
        method: 'GET',
    })
}


//ipcc报告分组
export function ipcc_groupbyfield(data) {
    return request({
        url: `/font-search/ipcc_groupbyfield`,
        method: 'POST',
        data
    })
}

//论文分组
export function paper_groupbyfield(data) {
    return request({
        url: `/font-search/paper_groupbyfield`,
        method: 'POST',
        data
    })
}

//satellite卫星分组
export function satellite_groupbyfield(data) {
    return request({
        url: `/font-search/satellite_groupbyfield`,
        method: 'POST',
        data
    })
}

//数据集分组
export function dataPacket_groupbyfield(data) {
    return request({
        url: `/font-search/dataPacket_groupbyfield`,
        method: 'POST',
        data
    })
}

//本中心数据集分组
export function dataset_groupbyfield(data) {
    return request({
        url: `/font-search/dataset_groupbyfield`,
        method: 'POST',
        data
    })
}

//载荷分组
export function dataLoad_groupbyfield(data) {
    return request({
        url: `/font-search/dataLoad_groupbyfield`,
        method: 'POST',
        data
    })
}

//查询关键词和专家

export function search_field_and_author_page(data) {
    return request({
        url: `/font-search/search_field_and_author_page`,
        method: 'POST',
        data
    })
}